<script setup>
const emits = defineEmits(['submit'])
const props = defineProps({
  action    : { type: String, default: 'post' },
  method    : { type: String, default: '' },
  target    : { type: String, default: '_self' },
  enctype   : { type: String, default: 'application/x-www-form-urlencoded' },
  novalidate: { type: Boolean, default: false },
  checkReload: { type: Boolean, default: true },
})

// リロード時に呼び出される関数
function handleBeforeUnload(event) {
  // ダイアログのメッセージを設定
  const message = 'このページを離れますか？';
  event.returnValue = message; // 一部のブラウザでは必要
  return message; // ダイアログに表示されるメッセージ
}

onMounted(() => {
  if (props.checkReload) {
    // ページがマウントされたときにイベントリスナーを追加
    window.addEventListener('beforeunload', handleBeforeUnload);
  }
});

onUnmounted(() => {
  if (props.checkReload) {
    // ページがアンマウントされたときにイベントリスナーを削除
    window.removeEventListener('beforeunload', handleBeforeUnload);
  }
});

const onSubmit = ($event) => {
  if (props.checkReload) {
    // ページがアンマウントされたときにイベントリスナーを削除
    window.removeEventListener('beforeunload', handleBeforeUnload);
  }
  emits('submit', $event)
}
</script>

<template>
  <form
    :action="action"
    :method="method"
    :target="target"
    :enctype="enctype"
    :novalidate="novalidate"
    @submit.prevent="onSubmit"
  >
    <slot></slot>
  </form>
</template>